<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="info">
                <div class="list">
                    <div class="one">手机号/邮箱</div>
                    <div class="account"><input type="text" v-model="keywords" @focus="qcac()"></div>
                    <div class="acone" v-show="tag">账号不能为空</div>
                    <div class="two">验证码</div>
                    <div class="code">
                        <input type="text" v-model="code">
                        <button  type="button" class="ant-btn"  @click="() => {
                        e => e.preventDefault()
                        sendCode()
                        }"><span>{{count}}</span></button>
                    </div>
                    <button class="submit" @click="regsub()">登&nbsp;&nbsp;录</button>
                    <div class="acone" v-show="subtag">请正确填写数据</div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
   name: "Login",
   components: {
    Footer,
    Header
  },
   data () {
    return {
        keywords: '',
        show: true,
        count: '获取验证码',
        timer: null,
        tag: false,
        subtag: false,
        code: ''
    }
  },
   methods: {
    // 发送验证码
    sendCode(keywords){
      if (this.keywords == ''){
        this.tag = true;
        return ;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            }
        }, 1000)
      }
      const params = {
          account: this.keywords,
          type: 'login',
      }
      this.$common.handlePost('/code/send', params).then(data => {
        if (data.code === 200) {
          this.$layer.alert("发送验证码成功");
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
    //去除提示信息
    qcac() {
        this.tag = false
    },
    //登录
    regsub() {
        if (this.keywords == '' || this.code == '') {
            this.subtag = true
            return 
        }
        const params = {
            account: this.keywords,
            code: this.code
        }
        this.$common.handlePost('/user/login', params).then(data => {
        if (data.code === 200) {
            console.log(data) 
            localStorage.setItem('token',data.data.token)
            localStorage.setItem('account',data.data.account)
            localStorage.setItem('userId',data.data.userId)
            localStorage.setItem('companyId',data.data.companyId)
            // storage.set(ACCESS_TOKEN, data.data.token, 7 * 24 * 60 * 60 * 1000)
            // storage.set('account', data.data.account, 7 * 24 * 60 * 60 * 1000)
            // storage.set('userId', data.data.userId, 7 * 24 * 60 * 60 * 1000)
            this.$layer.alert("登录成功111");
            this.$router.push({name: 'Home'})
        } else {
            this.$layer.alert(data.msg);
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
  }
}
</script>

 <style lang="less" scoped>
  @import '~@/assets/css/login.less';
</style>